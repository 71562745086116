<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed z-50 inset-0 overflow-y-auto">
        <div
          class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
            >
              <div>
                <div
                  class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100"
                >
                  <ExclamationIcon
                    class="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900"
                  >
                    請確認是否退出所屬供應商
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-base text-gray-700 mb-10">
                      {{ title }}
                    </p>
                    <p class="text-sm text-gray-500">
                      一旦送出將無法復原，<br />若要重新加入供應商，請聯繫供應商負責人。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
              >
                <button
                  type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-1 sm:mt-0 sm:col-start-1 sm:text-sm"
                  @click="close()"
                  ref="cancelButtonRef"
                >
                  取消
                </button>
                <button
                  type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-theme-3 text-base font-medium text-white hover:bg-theme-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-1 sm:col-start-2 sm:text-sm"
                  @click="leaveVendor()"
                >
                  確認退出
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { ExclamationIcon } from "@heroicons/vue/outline";

export default defineComponent({
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon
  },
  props: {
    title: {
      type: String
    }
  },
  setup() {},
  methods: {
    close() {
      this.$emit("dismiss");
    },
    leaveVendor() {
      // setTimeout(() => {
      //   sessionStorage.clear();
      //   this.$router.push("/login");
      //   // CloudFun.send("success", {
      //   //   subject: "退出供應商",
      //   //   content: "成功"
      //   // });
      // }, 1000);

      this.$model.dispatch("vendorMember/leave").then(
        payload => {
          console.log(
            "🚀 ~ file: Main.vue ~ line 708 ~ leaveVendor ~ payload",
            payload
          );
          if (payload.status) {
            sessionStorage.clear();
            this.$router.push("/login");
          } else {
            CloudFun.send("error", {
              subject: "退出供應商",
              content: "失敗"
            });
          }
        },
        reason => {
          CloudFun.send("error", {
            subject: "退出供應商失敗",
            content: reason.messag
          });
        }
      );
    }
  }
});
</script>
