<template>
  <div>
    <v-form
      :validation-schema="
        info.isVendor ? VendorValidationSchema : notVendorValidationSchema
      "
      :initial-values="info"
      @submit="save"
    >
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">基本資料閱覽與管理</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
            type="button"
            @click="close"
            class="button text-black bg-gray-500 shadow-md mr-2 inline-flex items-center"
          >
            <XIcon class="mr-2 w-4 h-4" />
            取消
          </button>
          <button
            type="submit"
            class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
          >
            <CheckIcon class="mr-2 w-4 h-4" />
            儲存
          </button>
        </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">帳號資料</div>
        <div class="">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              帳號
            </div>
            <div class=" text-base text-theme-1">
              <div class=" flex">
                <div class=" w-80 intro-x  block mr-3">
                  {{ info?.account }}
                </div>
                <!-- <input type="text" class=" w-80 intro-x input input--lg border border-gray-300 block mr-3" value="visiondesignx1@gmail.com" /> -->
                <!-- <button type="button" class="button  button--lg w-32 text-white bg-theme-1 align-top" disabled>
                  已驗證
                </button> -->
              </div>
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              身份
            </div>
            <div class=" text-base text-theme-1">{{ info?.identity }}</div>
          </div>
        </div>
      </div>

      <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">個人資料</div>
        <div class=" xl:grid grid-cols-2">
          <div class=" pr-5">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                真實姓名
              </div>
              <div class=" text-base text-theme-1">
                <div class=" flex">
                  <div class=" w-80 intro-x  block mr-3">
                    {{ info?.name }}
                  </div>
                  <!-- <input type="text" class=" w-80 intro-x input input--lg border border-gray-300 block mr-3" value="visiondesignx1@gmail.com" /> -->
                  <!-- <button type="button" class="button  button--lg w-32 text-white bg-theme-1 align-top" disabled>
                  已驗證
                </button> -->
                </div>
              </div>
            </div>

            <!-- <div class=" sm:flex mb-8">
              <CfInputBox
                label="真實姓名"
                name="name"
                placeholder="真實姓名"
                type="text"
                :isRequired="true"
                :modelValue="info.name"
                @update:modelValue="
                  val => {
                    info.name = val;
                  }
                "
              ></CfInputBox>
           
            </div> -->
            <div class=" sm:flex mb-8">
              <CfInputBox
                label="暱稱"
                name="displayName"
                placeholder="暱稱"
                type="text"
                :isRequired="true"
                :modelValue="info.displayName"
                @update:modelValue="
                  val => {
                    info.displayName = val;
                  }
                "
              ></CfInputBox>
              <!-- <div class=" sm:flex flex-wrap text-base">
                <input
                  type="text"
                  class=" w-80 intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="info.displayName"
                />
              </div>-->
            </div>
            <div class=" sm:flex justify-end mb-8">
              <div class=" sm:flex flex-wrap text-base">
                <CfInputRadioBox
                  id="realname_radio"
                  name="isUseDisplayName"
                  :defaultValue="false"
                  :modelValue="info.isUseDisplayName"
                  @update:modelValue="
                    val => {
                      info.isUseDisplayName = val;
                    }
                  "
                ></CfInputRadioBox>

                <!-- <input
                  id="realname_radio"
                  type="radio"
                  class="input border mr-2"
                  name="name_radio"
                  value="false"
                  v-model="info.isUseDisplayName"
                /> -->
                <label
                  class="cursor-pointer select-none mr-2"
                  for="realname_radio"
                  >以真實姓名進行互動</label
                >
              </div>
              <div class="sm:flex flex-wrap text-base">
                <CfInputRadioBox
                  id="nickname_radio"
                  name="isUseDisplayName"
                  :defaultValue="true"
                  :modelValue="info.isUseDisplayName"
                  @update:modelValue="
                    val => {
                      info.isUseDisplayName = val;
                    }
                  "
                ></CfInputRadioBox>
                <!-- <input
                  id="nickname_radio"
                  type="radio"
                  class="input border mr-2"
                  name="name_radio"
                  value="true"
                  v-model="info.isUseDisplayName"
                /> -->
                <label class="cursor-pointer select-none" for="nickname_radio"
                  >以暱稱進行互動</label
                >
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                性別
              </div>
              <div class=" text-base">
                {{ info?.sex ? (info.sex == "M" ? "男性" : "女性") : "" }}
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                E-mail
              </div>
              <div class=" text-base  items-center">
                <div class=" flex flex-row items-center">
                  <!-- <input type="text" class=" w-80 intro-x input input--lg border border-gray-300 block mr-3" value="09XX-XXXXXX" /> -->
                  <div class=" w-80 intro-x block mr-3 mb-4">
                    {{ info?.email }}
                  </div>

                  <div v-if="info?.isEmailVerified" class="items-center">
                    <CheckCircleIcon class="w-6 h-6 text-theme-9" />
                  </div>
                  <!-- <button
                    v-if="!info?.isEmailVerified"
                    type="button"
                    class="button w-32 text-white bg-theme-1 align-top mb-2"
                  >
                    未驗證
                  </button> -->
                </div>
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                聯絡電話
              </div>
              <div class="  text-base">
                <div class=" flex flex-row items-center">
                  <!-- <input
                    type="text"
                    class=" w-80 intro-x input input--lg border border-gray-300 block mr-3 mb-2"
                    v-model="info.mobile"
                  /> -->
                  <div class=" w-80 intro-x mb-4 block mr-3">
                    {{ info?.mobile }}
                  </div>
                  <div v-if="info?.isMobileVerified" class="items-center">
                    <CheckCircleIcon class="w-6 h-6 text-theme-9 " />
                  </div>
                  <!-- <button
                    v-if="!info?.isMobileVerified"
                    type="button"
                    class="button w-32 text-white bg-theme-1 align-top mb-2"
                  >
                    未驗證
                  </button> -->
                </div>
              </div>
            </div>
          </div>
          <div class="xl:pl-5">
            <div class=" sm:flex mb-8">
              <div class="sm:w-32 min-w-32 sm:text-right text-base mr-5">
                頭像
              </div>
              <div class="">
                <file-uploader
                  :ref="el => (uploader = el)"
                  :headers="headers"
                  :data="imageData"
                  id="user-photo-uri"
                  style="min-width: 200px; max-width: 200px; min-height: 200px; max-height: 200px;"
                  class="mr-10"
                  mode="image"
                  :modelValue="imageUrl"
                  :action="uploadAction"
                  :autoUpload="true"
                  :limitedWidth="200"
                  :limitedHeight="200"
                  @update:modelValue="
                    value => {
                      if (value?.archiveID) {
                        info.avatarImageID = value?.archiveID;
                      } else if (value == 'delete') info.avatarImageID = '';
                    }
                  "
                  @filter="uploaderFilter"
                />
                <!-- <a class="w-9.375 h-9.375 relative" href="#">
                  <span class=" absolute left-0 ring-0 -bottom-36 h-5 w-9.375 text-center z-10">上傳圖片</span>
                  <img class=" max-w-full h-auto" src="@/midone/assets/images/placeholders/200x200.jpg" style=" width:150px" alt="個人頭像" />
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="intro-y box p-10 mt-10" v-if="info.isVendor">
        <div class=" text-lg text-gray-600 mb-10">供應商資料</div>
        <div class=" lg:grid grid-cols-2 ">
          <div class=" pr-5">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                所屬供應商
              </div>
              <div class=" text-base text-theme-1">
                <div class=" flex">
                  <div class=" w-80 intro-x  block mr-3">
                    {{ vendor?.name }}
                  </div>
                  <!-- <input type="text" class=" w-80 intro-x input input--lg border border-gray-300 block mr-3" value="visiondesignx1@gmail.com" /> -->
                  <!-- <button type="button" class="button  button--lg w-32 text-white bg-theme-1 align-top" disabled>
                  已驗證
                </button> -->
                </div>
              </div>
            </div>

            <div class=" sm:flex mb-8">
              <div class=" sm:flex w-full text-base">
                <!-- <input type="text" class=" w-80 intro-x input input--lg border border-gray-300 block mr-3" v-model="info.contact_Title" />							 -->
                <CfInputBox
                  label="職稱"
                  name="contact_Title"
                  placeholder="職稱"
                  type="text"
                  :isRequired="true"
                  :modelValue="info.contact_Title"
                  @update:modelValue="
                    val => {
                      info.contact_Title = val;
                    }
                  "
                ></CfInputBox>
              </div>
            </div>

            <div class=" sm:flex mb-8">
              <div class=" sm:flex w-full text-base">
                <!-- <input type="text" class=" w-80 intro-x input input--lg border border-gray-300 block mr-3" v-model="info.contact_Email" />							 -->
                <div class=" sm:flex mb-8">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    業務信箱
                  </div>
                  <div class=" text-base text-theme-1">
                    <div class=" flex">
                      <div class=" w-80 intro-x  block mr-3">
                        {{ info?.contact_Email }}
                      </div>
                      <!-- <input type="text" class=" w-80 intro-x input input--lg border border-gray-300 block mr-3" value="visiondesignx1@gmail.com" /> -->
                      <!-- <button type="button" class="button  button--lg w-32 text-white bg-theme-1 align-top" disabled>
                  已驗證
                </button> -->
                    </div>
                  </div>
                </div>

                <!-- <CfInputBox
                  label="業務信箱"
                  name="contact_Email"
                  placeholder="業務信箱"
                  type="text"
                  :isRequired="true"
                  :modelValue="info.contact_Email"
                  @update:modelValue="
                    val => {
                      info.contact_Email = val;
                    }
                  "
                ></CfInputBox> -->
              </div>
            </div>

            <div class=" sm:flex mb-8">
              <div class=" sm:flex w-full text-base">
                <!-- <input type="text" class=" w-80 intro-x input input--lg border border-gray-300 block mr-3" v-model="info.contact_Tel" />							 -->
                <CfInputBox
                  label="業務電話"
                  name="contact_Tel"
                  placeholder="業務電話"
                  type="text"
                  :isRequired="true"
                  :modelValue="info.contact_Tel"
                  @update:modelValue="
                    val => {
                      info.contact_Tel = val;
                    }
                  "
                ></CfInputBox>
              </div>
            </div>
          </div>
          <div class="xl:pl-5">
            <div class=" flex mb-8">
              <div class="sm:flex text-base items-start text-theme-1">
                <div class="mr-3">
                  <button
                    type="button"
                    class="button  button--lg w-36 text-white bg-theme-1 align-top flex mb-3"
                    @click="leaveConfirm"
                  >
                    <XIcon class="mr-2 w-6 h-6" />
                    退出供應商
                  </button>
                </div>
                <div class="flex">
                  <AlertTriangleIcon class="mr-2 w-6 h-6" />
                  <div class="w-2/3 text-sm">
                    退出後您將無法使用供應商相關功能；若您持有其他供應商之邀請碼，請於退出時填寫邀請碼
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">社群帳號連結</div>
        <div class="">
          <div class=" pr-5">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-20 sm:text-right text-base mr-5">Google</div>
              <div class=" flex text-base">
                未連結
                <button
                  type="button"
                  class="button  w-32 text-white bg-theme-1 align-top ml-3"
                >
                  前往連結帳號
                </button>
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-20 sm:text-right text-base mr-5">Line</div>
              <div class=" flex text-base">
                未連結
                <button
                  type="button"
                  class="button  w-32 text-white bg-theme-1 align-top ml-3"
                >
                  前往連結帳號
                </button>
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-20 sm:text-right text-base mr-5">Feacebook</div>
              <div class=" flex text-base">
                未連結
                <button
                  type="button"
                  class="button  w-32 text-white bg-theme-1 align-top ml-3"
                >
                  前往連結帳號
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="intro-y box p-10 mt-10">
      <div class=" text-lg text-gray-600 mb-10">社群媒體推播通知設定</div>
      <div class="">
        <div class=" pr-5">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Google</div>
            <div class=" flex text-base">
              未連結
              <div class="">
                <input
                  type="checkbox"
                  class="input input--switch border ml-3"
                />
              </div>
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Line</div>
            <div class=" flex text-base">
              未連結
              <div class="">
                <input
                  type="checkbox"
                  class="input input--switch border ml-3"
                />
              </div>
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Feacebook</div>
            <div class=" flex text-base">
              未連結
              <div class="">
                <input
                  type="checkbox"
                  class="input input--switch border ml-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

      <!-- <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">帳號停用與刪除</div>
        <div class="">
          <div class=" pr-5">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-20 sm:text-right text-base mr-5">帳號刪除</div>
              <div class=" text-base">
                <button
                  type="button"
                  class="button  button--lg w-44 text-white bg-theme-1 align-top flex items-center"
                >
                  <XIcon class="w-4 h-4 mr-2" />
                  申請刪除帳號
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- END: HTML Table Data -->
    </v-form>
  </div>
  <leave-modal
    :show="open"
    :title="vendor?.name"
    @dismiss="open = false"
  ></leave-modal>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { defineComponent, ref, onMounted } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import router from "@/router";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import LeaveModal from "@/views/user-edit/modal/LeaveModal.vue";

export default defineComponent({
  components: {
    // Grid,
    // SelectBox,
    FileUploader,
    CfInputBox,
    CfInputRadioBox,
    VForm: Form,
    LeaveModal
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const uploader = ref<any>({});
    const info = ref<any>({});
    const auth = ref<any>({});
    const imageUrl = ref<any>({});
    const vendor = ref({} as any);
    const open = ref(false);

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getVendorInfo = () => {
      return model
        ?.dispatch("vendor/info")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const save = (data: any) => {
      if (!info.value.isUseDisplayName)
        info.value.displayName = info.value.name;

      model
        ?.dispatch("user/update", info.value)
        .then(response => {
          CloudFun.send(response.status ? "info" : "error", {
            subject: response.status ? "儲存成功" : "儲存失敗",
            content: response.status ? "修改完成" : response.message
          });
          if (response.status) {
            // setTimeout(() => {
            //   router.push('/user');
            // }, 1500);
            CloudFun.send("reloadAuth", "");
            close();
          }
        })
        .catch(error => {
          console.log(error.message);
          CloudFun.send("error", {
            subject: "儲存失敗",
            content: error.messag
          });
        });
    };

    const close = () => {
      emit("showEdit", false);
    };

    onMounted(async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/");

      info.value = await getInfo();
      if (info.value?.avatarImageID)
        imageUrl.value = `${uploadAction}/${info.value.avatarImageID}`;

      if (info.value?.mobile)
        info.value.mobile = info.value.mobile.replace("-", "");

      vendor.value = await getVendorInfo();
    });

    const uploadAction = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const imageData = { ProductID: 0 };

    // const phoneExp = /^09[0-9]{8}$/;
    const phoneExp = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/;

    const notVendorValidationSchema = Yup.object().shape({
      // name: Yup.string().required("必要欄位"),
      displayName: Yup.string().required("必要欄位")
    });

    const VendorValidationSchema = Yup.object().shape({
      // name: Yup.string().required("必要欄位"),
      isUseDisplayName: Yup.string()
        .required("必要欄位")
        .oneOf(["true", "false"]),
      displayName: Yup.string().test(
        "isUseDisplayName",
        "必要欄位",
        (value, schema) => {
          if (schema.parent.isUseDisplayName === "true")
            return value != null && value.length > 0;
          return true;
        }
      ),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Tel: Yup.string()
        .required("必要欄位")
        .test("mobile", "業務電話格式錯誤", (value, testContext) => {
          if (value?.startsWith("09")) {
            return value.match(phoneExp) ? true : false;
          }
          return true;
        })
    });

    return {
      info,
      uploader,
      uploadAction,
      headers,
      imageData,
      save,
      imageUrl,
      notVendorValidationSchema,
      VendorValidationSchema,
      close,
      vendor,
      open
    };
  },
  methods: {
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    leaveConfirm() {
      this.open = true;
    }
  }
});
</script>

<style lang="scss">
.min-w-20 {
  min-width: 5rem;
}
</style>
