
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { ExclamationIcon } from "@heroicons/vue/outline";

export default defineComponent({
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon
  },
  props: {
    title: {
      type: String
    }
  },
  setup() {},
  methods: {
    close() {
      this.$emit("dismiss");
    },
    leaveVendor() {
      // setTimeout(() => {
      //   sessionStorage.clear();
      //   this.$router.push("/login");
      //   // CloudFun.send("success", {
      //   //   subject: "退出供應商",
      //   //   content: "成功"
      //   // });
      // }, 1000);

      this.$model.dispatch("vendorMember/leave").then(
        payload => {
          console.log(
            "🚀 ~ file: Main.vue ~ line 708 ~ leaveVendor ~ payload",
            payload
          );
          if (payload.status) {
            sessionStorage.clear();
            this.$router.push("/login");
          } else {
            CloudFun.send("error", {
              subject: "退出供應商",
              content: "失敗"
            });
          }
        },
        reason => {
          CloudFun.send("error", {
            subject: "退出供應商失敗",
            content: reason.messag
          });
        }
      );
    }
  }
});
