
/* eslint-disable */
import CloudFun, { defineComponent, ref, onMounted } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import router from "@/router";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import CfInputRadioBox from "@/cloudfun/components/CfInputRadioBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import LeaveModal from "@/views/user-edit/modal/LeaveModal.vue";

export default defineComponent({
  components: {
    // Grid,
    // SelectBox,
    FileUploader,
    CfInputBox,
    CfInputRadioBox,
    VForm: Form,
    LeaveModal
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const uploader = ref<any>({});
    const info = ref<any>({});
    const auth = ref<any>({});
    const imageUrl = ref<any>({});
    const vendor = ref({} as any);
    const open = ref(false);

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getVendorInfo = () => {
      return model
        ?.dispatch("vendor/info")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const save = (data: any) => {
      if (!info.value.isUseDisplayName)
        info.value.displayName = info.value.name;

      model
        ?.dispatch("user/update", info.value)
        .then(response => {
          CloudFun.send(response.status ? "info" : "error", {
            subject: response.status ? "儲存成功" : "儲存失敗",
            content: response.status ? "修改完成" : response.message
          });
          if (response.status) {
            // setTimeout(() => {
            //   router.push('/user');
            // }, 1500);
            CloudFun.send("reloadAuth", "");
            close();
          }
        })
        .catch(error => {
          console.log(error.message);
          CloudFun.send("error", {
            subject: "儲存失敗",
            content: error.messag
          });
        });
    };

    const close = () => {
      emit("showEdit", false);
    };

    onMounted(async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/");

      info.value = await getInfo();
      if (info.value?.avatarImageID)
        imageUrl.value = `${uploadAction}/${info.value.avatarImageID}`;

      if (info.value?.mobile)
        info.value.mobile = info.value.mobile.replace("-", "");

      vendor.value = await getVendorInfo();
    });

    const uploadAction = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const imageData = { ProductID: 0 };

    // const phoneExp = /^09[0-9]{8}$/;
    const phoneExp = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/;

    const notVendorValidationSchema = Yup.object().shape({
      // name: Yup.string().required("必要欄位"),
      displayName: Yup.string().required("必要欄位")
    });

    const VendorValidationSchema = Yup.object().shape({
      // name: Yup.string().required("必要欄位"),
      isUseDisplayName: Yup.string()
        .required("必要欄位")
        .oneOf(["true", "false"]),
      displayName: Yup.string().test(
        "isUseDisplayName",
        "必要欄位",
        (value, schema) => {
          if (schema.parent.isUseDisplayName === "true")
            return value != null && value.length > 0;
          return true;
        }
      ),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Tel: Yup.string()
        .required("必要欄位")
        .test("mobile", "業務電話格式錯誤", (value, testContext) => {
          if (value?.startsWith("09")) {
            return value.match(phoneExp) ? true : false;
          }
          return true;
        })
    });

    return {
      info,
      uploader,
      uploadAction,
      headers,
      imageData,
      save,
      imageUrl,
      notVendorValidationSchema,
      VendorValidationSchema,
      close,
      vendor,
      open
    };
  },
  methods: {
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    leaveConfirm() {
      this.open = true;
    }
  }
});
